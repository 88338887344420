































import MNotificationVue from "@/mixins/MNotification.vue";
import { IOption } from "@/models/interface-v2/common.interface";
import { IBankData } from "@/models/interface-v2/contact.interface";
import { contactServices } from "@/services-v2/contact.service";
import Vue from "vue";
export default Vue.extend({
  name: "SelectBank",
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    supplierId: {
      type: String,
      default: undefined
    },
    forList: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dtOpt: [] as IOption<IBankData>[],
    };
  },
  watch: {
    supplierId: {
      immediate: true,
      handler: "onChangeSupplier"
    }
  },
  methods: {
    async onChangeSupplier(newVal: string): Promise<void> {
      try {
        if (!newVal) return;
        const res = await contactServices.getContactData(newVal);
        this.dtOpt = res.bankDataList.map(x => ({
          key: `${x.bankAccName} - ${x.bankName} - ${x.bankAccNumber}`,
          value: `${x.bankAccName} - ${x.bankName} - ${x.bankAccNumber}`,
          meta: x,
        }));
        this.clear();
      } catch (error) {
        console.error(error);
      }
    },
    clear(): void {
      this.$emit("input", undefined);
      this.$emit("on-clear", { value: "", meta: undefined });
    },
    onSelect(e: string, meta): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
  }
});
